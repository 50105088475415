.div-imagen {
  width: 100%;
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */

  .div-imagen-img {
    max-width: 100%; /* Ajusta el ancho máximo para que se ajuste al contenedor */
    height: auto; /* Mantiene la proporción de aspecto */
  }
}

@media (min-width: 768px) {
  .div-imagen {
    .div-imagen-img {
      max-width: 30%; /* Ajusta el ancho máximo para dispositivos de escritorio */
    }
  }
}
