.banner {
  background-color: black;
  display: flex;
  justify-content: space-between;
  font-size: 8px;
  padding: 0.3em;
  color: white;
}

.span-informative {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
