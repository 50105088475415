.footer {
  background-color: #13384f;
  color: #fff;
  padding-bottom: 35px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 13px;

  .footer-section {
    flex: 1;
    align-items: center;
    text-align: center;
    max-width: 300px;

    h3 {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 10px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      margin-bottom: 5px;
    }

    a {
      color: #fff;
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
        color: #ccc;
      }
    }
  }
}

// Media query para pantallas más pequeñas (por ejemplo, dispositivos móviles)
@media screen and (max-width: 768px) {
  .footer {
    flex-direction: column; // Cambia la dirección de los elementos a columnas
    align-items: center; // Centra los elementos horizontalmente
  }
}
