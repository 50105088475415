.quienes-somos {
  display: flex;
  justify-content: center; // Centra horizontalmente el contenido
  padding: 30px; // Agrega algo de espacio alrededor del contenido
  color: #13384f;

  .quienSomos {
    display: flex;
    flex-direction: column;
    align-items: center; // Centra verticalmente el contenido
    max-width: 800px; // Ancho máximo del contenedor
    margin: 0 auto; // Centra el contenedor horizontalmente

    .quien-somos-enter {
      text-align: center;
      margin-bottom: 20px; // Agrega espacio debajo del texto
    }

    .image-portada {
      width: 50%; // Haz que la imagen ocupe todo el ancho del contenedor
      border-radius: 10px; // Añade bordes redondeados a la imagen
    }
  }
}
