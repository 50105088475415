.contact-form-container {
  display: block;
  justify-content: center;
  align-items: center;
}

h3 {
  text-align: center;
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.contact-form {
  width: 80%;
  max-width: 400px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #13384f;
  color: #fff;

  h2 {
    margin-bottom: 20px;
  }

  .form-group {
    margin-bottom: 20px;

    label {
      display: block;
      margin-bottom: 8px;
      font-size: 14px;
    }

    input[type="text"],
    input[type="email"],
    textarea {
      width: 94%;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    textarea {
      height: 100px;
    }
  }

  button {
    width: 50%;
    padding: 10px;
    background-color: #13384f;
    color: #fff;
    font-weight: bold;
    border: 2px solid white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #13384f;
    }
  }
}
