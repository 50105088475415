.specialists-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-bottom: 25px;
}

.specialist-card {
  background-color: #13384f;
  color: white;
  width: 300px;
  margin: 10px;
  padding: 9px;
  border-radius: 2px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.specialist-image {
  width: 100%;
  border-radius: 2px;
}

.specialist-info {
  margin-top: 10px;
}

.specialist-name {
  font-size: 1.5rem;
}

.specialist-details {
  margin-top: 10px;
}
