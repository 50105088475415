.section {
  display: flex;
  margin: 25px;
  background-color: #ffffff;
  text-align: justify;
  border: 1px solid #13384f; /* Añadido borde alrededor de cada sección */
  align-items: stretch; /* Asegura que todas las secciones tengan la misma altura */
  box-shadow: 6px 6px 14px rgba(0, 0, 0, 0.1);
  color: #13384f;
}

.content {
  display: flex;
  width: 100%;
  font-size: 13px;
}

.text-wrapper {
  width: 60%;
  padding: 15px;
  text-align: justify; /* Cambiado a "justify" para justificar el texto */
}

.image-wrapper {
  width: 40%;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.section-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover; /* Ajusta la imagen para cubrir todo el contenedor */
}

.button-mostrar-mas {
  background-color: transparent;
  border: none;
  color: #13384f;
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
    align-items: center;
  }

  .text-wrapper {
    width: 80%;
  }

  .image-wrapper {
    width: 100%;
    height: 200px; /* Altura fija para la imagen en dispositivos móviles */
    padding-top: 0; /* Eliminar el padding top */
  }

  .section-image {
    position: relative; /* Cambiar a posición relativa en dispositivos móviles */
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ajusta la imagen para cubrir todo el contenedor */
  }
}
