.conjunt {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.span {
  color: #13384f;
  font-size: large;
  font-weight: bold;
}

.parraf {
  color: #13384f;
  text-justify: auto;
}
