.navigation {
  background-color: #13384f;
  color: whitesmoke;
  display: flex;
  justify-content: space-around;
  padding: 0.7em;
}
.enlance {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.icon {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
}
