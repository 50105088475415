.combo {
  display: flex;
  flex-wrap: wrap; // Permitir que las imágenes se envuelvan en varias líneas
  justify-content: center;
  padding: 0px 10px;
}

@media only screen and (min-width: 768px) {
  .combo {
    flex-wrap: nowrap; // Evitar que las imágenes se envuelvan en dispositivos de escritorio
  }

  .imagen-1 {
    width: 13%; // Ajustar el ancho de las imágenes para mostrar todas en una sola fila en dispositivos de escritorio
    max-width: 200px; // Limitar el ancho máximo de las imágenes
    margin: 1em; // Añadir un margen entre las imágenes
  }
}

@media only screen and (max-width: 767px) {
  .imagen-1 {
    width: 30%; // Ajustar el ancho de las imágenes para mostrar dos por línea en dispositivos móviles
    max-width: 150px; // Limitar el ancho máximo de las imágenes en dispositivos móviles
    margin: 1em; // Añadir un margen entre las imágenes en dispositivos móviles
  }
}

.texto-centrado {
  background-color: black;
  text-align: center;
  color: white;
  margin: 2em 0; // Añadir un margen arriba y abajo del texto centrado
}

h3 {
  text-align: center;
  margin: 0;
  margin-top: 40px;
}

.h3-cc {
  color: #13384f;
}
