.base-div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 800px;
  margin: 20px auto;

  .base-div-1 {
    width: 45%;
    background-color: #13384f;
    color: white;
    border-radius: 3px;
    padding: 10px;
    margin: 10px;
    text-align: center;
    font-size: 12px;

    .base-div-2 {
      font-size: 12px;
    }

    @media (max-width: 768px) {
      width: 100%; // Cambiar a ocupar todo el ancho disponible en dispositivos móviles
    }
  }
}
